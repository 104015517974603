@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'


    
.tutorial-button
    background-color: white
    font:
        size: .8rem
    border: 0
    margin-right: $base-grid*0.5
    @include focus-with-background() 
    @include media-breakpoint-up(lg)
        margin-right: $base-grid * 2
    .tutorial-button__icon
        svg
            width: 32px
            height: 32px
            background-color: $primary
            color: white
            border: 0
            border-radius: 50%
    .tutorial-button__description
        display: none
        @include media-breakpoint-up(lg)
            display: block
            font-family: $font-family-base
            color: $primary

.shepherd-box
    .shepherd-content
        .shepherd-header
            background-color: $primary
            .shepherd-title
                font:
                        size: 1rem
                @include media-breakpoint-up(md)
                    font:
                        size: 1.5rem
                color: white
                text-align: center
        .shepherd-text
            font:
                    size: .8rem
            @include media-breakpoint-up(md)
                font:
                    size: 1rem
            text-align: center
        .shepherd-footer
            justify-content: space-around
            .shepherd-button
                font:
                    size: .8rem
                color: white
                background: $primary
            @include media-breakpoint-up(md)
                font:
                    size: 1rem

    